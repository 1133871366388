<template>

 <div class="col-12"> 
 <h2>センサ一覧</h2>
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>センサ名</th>
              <th>ゲートウェイの接続状態</th>              
            </tr>
          </thead>
          <tbody>
                    
              <tr v-for="(device, key) in devices" :key="key">
                <td>
                <router-link :to="{ name: 'SensorState',  params : this.vect(device)   }">
                 <span >  {{ device.node_id }}</span >
                </router-link>
                </td>
                <td>
                 <span >{{ device.name}}</span >
                </td>
                <td> 
                 <span  
                    v-bind:class="{ 'text-success': (0==device.status.current_status.data.state.indexOf('DeviceConnected'))?true:false,  'text-danger': (0==device.status.current_status.data.state.indexOf('DeviceDisconnected'))?true:false }" 
                 >   {{ moment( device.status.current_status.data.timestamp , 'YYYY/MM/DD, hh:mm:ss A')  }} , {{ device.status.current_status.data.state }} </span >
                </td>
            </tr>
          </tbody>
        </table>
      </div>
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";

import axios from 'axios';
import moment from "moment";

export default defineComponent({
  name: "Snow",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
  devices: [] ,
  key : 0
  } },
  computed: {},
  mounted : async function(){
  this.$emit('show-header');
    const result = await  axios.get("/api/v2/devices/",{ withCredentials: true });
  let devices  = result.data.data;
   console.log(devices);
    if (  devices.length ){
        for( var i =0 ; i < devices.length ; i++){
           try{
           
             const status = await  axios.get("/api/v2/devices/"+ devices[i].node_id + '/status',{ withCredentials: true });      
             
             
             if(status.error ){ 
             console.log(status);
             continue; }
             
                   devices[i].status = status.data;
                   const device = devices[i];
                 this.devices.push(device); 
            }catch (e){
                console.log(e);
            }
         }
    } 
     console.log(this.devices);
 },
  methods: {
        vect (data) {
            
           var d = JSON.parse(  JSON.stringify(data) );
           // delete d.status;
           d = Object.assign(d,  d.status.data);
           d = Object.assign(d,  d.status.current_status.data);
          return d;
        },
        moment(date, format) { 
        return moment(date).local().format(format)
        } 
    }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}